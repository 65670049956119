import { IconButton } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import InputAdornment from '@material-ui/core/InputAdornment';
import MuiLink from '@material-ui/core/Link';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tab from '@material-ui/core/Tab/Tab';
import Tabs from '@material-ui/core/Tabs';
import { CloudDownload } from '@material-ui/icons';
import { CheckCircle, RemoveCircle } from '@material-ui/icons';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import { Storage } from 'aws-amplify';
import gql from 'graphql-tag';
import { castArray } from 'lodash';
import { cloneDeep, debounce } from 'lodash';
import get from 'lodash/get';
import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  lazy,
  Suspense
} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import AuthContext from '../../components/AuthContext';
import TextField from '../../components/TextField';
import { WEATHER_API_PATH } from '../../Constants';
import { WEATHER_API_LAT_LNG_PATH } from '../../Constants';
import { SUBMIT_ANSWER } from '../../Constants';
import {
  PLACEHOLDER_IMAGE,
  BUCKET_NAME,
  modeToQuestionTypeId,
  POWER_WASH_TYPE,
  INSPECTION_TYPE,
  AUDIT_TYPE,
  INCLUSIVE_TYPE,
  POWER_WASH_ICON
} from '../../Constants';
import useDeleteMutationFHG from '../../fhg/components/data/useDeleteMutationFHG';
import useMutationFHG from '../../fhg/components/data/useMutationFHG';
import useQueryFHG from '../../fhg/components/data/useQueryFHG';
import ModalDialog from '../../fhg/components/dialog/ModalDialog';
import DisplayError from '../../fhg/components/DisplayError';
import Prompt from '../../fhg/components/edit/Prompt';
import useEditData from '../../fhg/components/edit/useEditData';
import Grid from '../../fhg/components/Grid';
import Loading from '../../fhg/components/Loading';
import { DELETE_ACTION } from '../../fhg/components/ReactSelect';
import ReactSelect from '../../fhg/components/ReactSelect';
import FHGTypography from '../../fhg/components/Typography';
import Typography from '../../fhg/components/Typography';
import useMessage from '../../fhg/hooks/useMessage';
import {convertImageToWrapper, fetchData} from '../../fhg/utils/Utils';
import StyledDropZone from '../../fhg/components/data/StyledPhotoDropZone';
import { CircleIcon } from '../../Icons';
import PerimeterConcernsBuilder from '../builder/PerimeterConcernsBuilder';
import { PROPERTY_FRAGMENT } from '../Types';
import {
  INCOMPLETE_STATUS,
  COMPLETE_STATUS,
  PARTIAL_STATUS,
  DELETED_STATUS
} from './AnswerDetailEdit';
import CameraDialog from './CameraDialog';
import { checkAreaStatus, clearAreaStatus } from './CheckStatusUtil';
import Conclusion from './Conclusion';
import InspectionChecklist from './InspectionChecklist';
import InspectionPanel from './InspectionPanel';
import Summary from './Summary';
import TechnicalNotes from './TechnicalNotes';
import {uploadFileS3} from '../../fhg/utils/SubmitUtils';
import ComprehensiveInclusiveAudit from './inclusive/ComprehensiveInclusiveAudit';
import {MuiThemeProvider, responsiveFontSizes} from '@material-ui/core/styles';
import {materialTheme} from '../Main';
import createTheme from '@material-ui/core/styles/createTheme';

const INCLUSIVE_INSPECTION_TAB = 'inclusive';
const INCLUSIVE_AUDIT_TAB = 'inclusiveAudit';
const INCLUSIVE_INSPECTION_IMAGE_TAB = 'inclusiveImage';
const INSPECT_TAB = 'inspect';
const CHECKLIST_TAB = 'checklist';
const CONCLUSION_TAB = 'conclusion';
const SUMMARY_TAB = 'summary';
const NOTES_TAB = 'notes';
const PERIMETER_CONCERNS_TAB = 'perimeterConcerns';

const InspectionBuilder = lazy(() => import('../builder/InspectionBuilder'));

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    minHeight: 400,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0.5),
    },
  },
  deleteInspectionStyle: {},
  dividerStyle: {
    backgroundColor: theme.palette.secondary.main,
    height: `25px !important`,
    margin: 'auto',
  },
  relatedRightSpace: {
    marginRight: theme.spacing(1),
  },
  relatedTopSpace: {
    marginTop: theme.spacing(1),
  },
  rightDivider: {
    borderRight: `1px solid ${theme.palette.divider}`,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      borderRight: `none`,
    }
  },
  shrinkRightDivider: {
    borderRight: `1px solid ${theme.palette.divider}`,
    marginBottom: theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      borderRight: `none`,
    }
  },
  propertyInfoStyle: {
    borderRight: `1px solid ${theme.palette.divider}`,
    marginBottom: theme.spacing(1),
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
      paddingLeft: theme.spacing(3),
    },
  },
  propertyPhotoStyle: {
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
    },
  },
  photoStyle: {
    maxWidth: '100%',
    width: 200,
    height: 150,
    [theme.breakpoints.down('xs')]: {
      width: 100,
      height: 75,
    }
  },
  shrinkPhotoStyle: {
    maxWidth: '100%',
    width: 160,
    height: 120,
  },
  buttonStyle: {
    whiteSpace: 'nowrap',
  },
  leftButtonStyle: {
    composes: '$buttonStyle',
    marginRight: theme.spacing(1),
  },
  rightButtonStyle: {
    composes: '$buttonStyle',
    marginLeft: theme.spacing(1),
  },
  tabStyle: {
    fontWeight: 500,
    marginBottom: theme.spacing(1),
  },
  indicatorStyle: {
    marginBottom: theme.spacing(2),
  },
  backdropStyle: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  headerStyle: {
    '-webkit-transition': 'all 0.5s ease-in-out',
    '-moz-transition': 'all 0.5s ease-in-out',
    transition: 'all 0.5s ease-in-out',
  },
  shrinkStyle: {},
  weatherStyle: {
    marginTop: theme.spacing(2),
  },
}));

const AREA_FRAGMENT = gql`
  fragment areaInfoForInspect on Area {
    id
    name
    description
    isDeleted
    equipment {
      id
      name
      isDeleted
      questionAliases {
        id
        question {
          id
          text
          entrySetValue
          questionType {
            id
            name
          }
        }
        isDeleted
        markedForAdd
        markedForRemove
      }
    }
  }
`;

const REPORT_FRAGMENT = gql`
  fragment reportInfoForView on Report {
    id
    isDeleted
    isCompleted
    description
    checkedInspectorItems
    weather
    surfaceTypeId
    imageS3
    imageData {
      imageS3
    }
    inclusiveImageData {
      imageS3
    }
    conclusion
    summary
    answers {
      id
      isDeleted
      questionAliasId
      priorityId
      statusId
      text
      imageData {
        imageS3
      }
      note
      createdDateTime
      updatedDateTime
      questionAlias {
        id
        isDeleted
        markedForAdd
        markedForRemove
        question {
          id
          text
        }
      }
    }
    perimeterConcerns {
      id
      comments
      distance
      isDeleted
      perimeterConcernTypeId
      priorityId
    }
    template {
      id
    }
  }
`;

const SURFACE_TYPE_ALL_QUERY = gql`
  query getSurfaceTypeAll {
    surfaceTypes: surfaceType_All {
      id
      value: id
      name
      label: name
    }
  }
`;

const SURFACE_TYPE_CREATE = gql`
  mutation surfaceTypeCreate($name: String!) {
    surfaceType: surfaceType_Create(surfaceType: { name: $name }) {
      id
      value: id
      name
      label: name
    }
  }
`;

const SURFACE_TYPE_DELETE = gql`
  mutation deleteSurfaceType($id: Int!) {
    surfaceType_Delete(surfaceTypeId: $id)
  }
`;

export const PROPERTY_BY_ID_QUERY = gql`
  query propertyByIdQuery($id: Int!, $reportId: Int!) {
    property: property_ById(propertyId: $id) {
      ...propertyInfo
    }
    areas: area_AllByReportId(reportId: $reportId) {
      ...areaInfoForInspect
    }
    report: report_ById(reportId: $reportId) {
      ...reportInfoForView
    }
  }
  ${REPORT_FRAGMENT}
  ${AREA_FRAGMENT}
  ${PROPERTY_FRAGMENT}
`;

const REPORT_DELETE = gql`
  mutation reportDelete($id: Int!) {
    report_Delete(reportId: $id)
  }
`;

const REPORT_UPDATE_INSPECTION = gql`
  mutation ReportUpdateInspection($reportId: Int!, $imageBucket: String, $inclusiveImageS3Data: ImageS3Data, $description: String, $isCompleted: Boolean,
     $weather: String, $surfaceTypeId: Int
  ) {
    report: report_Update(reportId: $reportId, report: {imageBucket: $imageBucket, description: $description,
       isCompleted: $isCompleted, weather: $weather, surfaceTypeId: $surfaceTypeId, inclusiveImageS3Data: $inclusiveImageS3Data
      }
    ) {
      ...reportInfoForView
    }
  }
  ${REPORT_FRAGMENT}
`;

export const INSPECTOR_COMPLETE = 'inspector complete';

export default function InspectionView() {
  let imageRef = useRef();

  const history = useHistory();
  const { isAdmin } = useContext(AuthContext);
  const [isCameraOpen, setCameraOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(INSPECT_TAB);
  const [isLargeHeader, setIsLargeHeader] = useState(window.innerHeight > 580);
  const [isInProgress, setIsInProgress] = useState();
  const [overallStatus, setOverallStatus] = useState(INCOMPLETE_STATUS);
  const [savingError, setSavingError] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [property, setProperty] = useState({ city: {}, state: {} });
  const [isFailedWeather, setIsFailedWeather] = useState(false);
  const [weatherData, setWeatherData] = useState();

  const [areas, setAreas] = useState([]);
  const [statusAreas, setStatusAreas] = useState([]);
  const [report, setReport] = useState();
  const [showEdit, setShowEdit] = useState(false);
  const classes = useStyles();
  const [isEditable, setIsEditable] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [image, setImage] = useState();
  const {
    id: idParam = 0,
    reportId: reportIdParam = 0,
    mode = ''
  } = useParams();
  const id = +idParam;
  const reportId = +reportIdParam;
  const questionTypeId = modeToQuestionTypeId[mode];
  const questionType = useMessage('questionType.' + mode);
  const [reportUpdate, reportUpdateStatus] = useMutationFHG(
    REPORT_UPDATE_INSPECTION
  );
  const { loading, data, statusComponent } = useQueryFHG(PROPERTY_BY_ID_QUERY, {
    variables: { id: +id, reportId: +reportId }, fetchPolicy: 'cache-and-network'});
  const confirmKey = {
    [INSPECTION_TYPE.toString()]: 'answer.deleteInspection.confirmation',
    [AUDIT_TYPE.toString()]: 'answer.deleteAudit.confirmation',
    [POWER_WASH_TYPE.toString()]: 'answer.deletePowerWash.confirmation',
    [INCLUSIVE_TYPE.toString()]: 'answer.deleteInclusive.confirmation'
  }[questionTypeId?.toString()];

  const [reportDelete, reportDeleteStatusComponent, reportConfirmComponent] =
    useDeleteMutationFHG(REPORT_DELETE, undefined, {
      titleKey: 'delete.title',
      confirmKey,
    });

  const [
    editValues,
    handleEditChange,
    { setEditValues, hasValue, handleSelectChange }
  ] = useEditData(undefined, undefined, undefined, (value) => {
    submitChangeDelayed(value);
  });

  const {loading: surfaceTypeLoading,
    data: surfaceTypeData,
    statusComponent: surfaceTypeStatus
  } = useQueryFHG(SURFACE_TYPE_ALL_QUERY);
  const [surfaceTypeDelete, deleteSurfaceTypeStatusComponent] =
    useMutationFHG(SURFACE_TYPE_DELETE);
  const [surfaceTypeCreate, createSurfaceTypeStatusComponent] =
    useMutationFHG(SURFACE_TYPE_CREATE);

  const useResize = () => {
    const height = window.innerHeight;
    setIsLargeHeader(height > 580);
  };

  // Debounce the submit changes call so that changes to text fields don't cause continual submits.
  const handleResizeDebounced = useRef(debounce(useResize, 500)).current;

  /**
   * Submit the changes to the server.
   * @param weather The edited weather.
   * @return {Promise<void>}
   */
  const submitChange = async (editValues) => {
    let surfaceTypeId = editValues?.surfaceTypeId?.value;

    await reportUpdate({variables: {reportId, ...editValues, surfaceTypeId, surfaceTypeId_Edit: undefined}});
  };

  // Debounce the submit changes call so that changes to text fields don't cause continual submits.
  const submitChangeDelayed = useRef(
    debounce(submitChange, SUBMIT_ANSWER)
  ).current;

  useEffect(() => {
    window.addEventListener('resize', handleResizeDebounced);
    return () => {
      // eslint-disable-next-line no-unused-expressions
      submitChangeDelayed.flush;
      window.removeEventListener('resize', handleResizeDebounced);
    };
  }, []);

  useEffect(() => {
    if (!!data) {
      const areas = get(data, 'areas', []);
      setProperty(get(data, 'property', { city: {}, state: {} }));
      setAreas(areas);
      const report = get(data, 'report', {}) || {};
      setReport(report);
      setIsEditable((report.isCompleted !== true) || isAdmin);
      setIsComplete(
        report.isCompleted || report.description === INSPECTOR_COMPLETE
      );
    }
  }, [data]);

  useEffect(() => {
    if (report && areas) {
      const answers = get(data, 'report.answers', []);
      const statusAreas = cloneDeep(areas);
      clearAreaStatus(statusAreas, answers);
      const { status, isInProgress } = checkAreaStatus(statusAreas, answers);
      setStatusAreas(statusAreas);

      setOverallStatus(status);
      setIsInProgress(isInProgress);
    }
  }, [areas, report]);

  /**
   * Add a new question. Show the edit panel for the new question.
   */
  const deleteInspection = () => {
    reportDelete(
      { variables: { id: reportId } },
      () => {
        history.push(`/inspect/${property.id}`);
      },
      error => {
        console.log('Error deleting inspection report', error);
      });
  };

  const handleDrop = async (file) => {
    let inclusiveImageS3Data = null;

    setImage(file);

    if (file) {
      const imageBucket = await uploadFileS3(file);
      inclusiveImageS3Data = {imageBucket};
    }

    await reportUpdate({variables: {reportId, inclusiveImageS3Data}});
  };

  const handleCameraOpen = () => {
    setCameraOpen(true);
  };

  const handleCameraClose = () => {
    setCameraOpen(false);
  };

  const handleCameraSubmit = async (image) => {
    setCameraOpen(false);

    if (image) {
      setIsSaving(true);
      const imagePath = `upload/Photo_${Date.now()}.jpg`;
      imageRef.current.src = image.src;

      Storage.put(imagePath, image.blob, {
        level: 'public',
        contentType: get(image, 'blob.type', 'image/jpeg')
      })
        .then(() => {
          reportUpdate({
            variables: { reportId, imageBucket: `${BUCKET_NAME}/${imagePath}` }
          }).finally(() => {
            setIsSaving(false);
          });
        })
        .catch(error => {
          console.log('Error uploading file', error);
          setSavingError(error);
          setIsSaving(false);
        });
    }
  };

  const doShowEdit = () => {
    setShowEdit(true);
  };

  const closeEdit = () => {
    setShowEdit(false);
  };

  /**
   * Handle changes to the selected tab.
   *
   * @param event The target of the event that triggered the change.
   * @param value The value of the change.
   */
  const handleChange = (event, value) => {
    setSelectedTab(value);
  };

  /**
   * Toggle the complete status for the inspection.
   */
  const doComplete = async () => {
    if (!isComplete) {
      if (!showConfirm && overallStatus !== COMPLETE_STATUS) {
        setShowConfirm(true);
      } else {
        setShowConfirm(false);
        setIsComplete(true);
        if (!hasValue('weather')) {
          await handleFetchWeather(false);
        }

        reportUpdate({
          variables: {
            reportId,
            description: INSPECTOR_COMPLETE,
            isCompleted: false
          }
        });
      }
    } else {
      setIsComplete(false);
      reportUpdate({
        variables: { reportId, description: '', isCompleted: false }
      });
    }
  };

  /**
   * Cancel the confirm dialog for marking an inspection complete.
   */
  const cancelConfirm = () => {
    setShowConfirm(false);
    setIsComplete(false);
    reportUpdate({
      variables: { reportId, description: '', isCompleted: false }
    });
  };

  /**
   * Can the surface type be deleted.
   *
   * @param surfaceType The surfaceType to check.
   * @param action The action to check
   * @return {*|boolean} Indicates if the action can be performed on the selected surfaceType.
   */
  const handleCanSurfaceTypeAction = (surfaceType, action) => {
    return action === DELETE_ACTION && surfaceType?.hasOwnProperty('id');
  };

  const getRefetchSurfaceTypeQueries = () => ([{ query: SURFACE_TYPE_ALL_QUERY }]);

  /**
   * Delete the surface type selected.
   *
   * @param deleteOption The surfaceType to delete.
   */
  const handleSurfaceTypeDelete = (deleteOption) => {
    const surfaceTypeToDelete = castArray(deleteOption);
    const id = get(surfaceTypeToDelete, '[0].id');

    surfaceTypeDelete({
      variables: { id },
      refetchQueries: getRefetchSurfaceTypeQueries
    });

    setEditValues({ ...editValues, surfaceTypeId: undefined });
  };

  /**
   * Handle the area select changes.
   *
   * @param event The select event.
   * @param name The name of the selected component.
   * @param isMulti Indicates if the select is a multi select.
   * @param options The option for the action.
   *
   * @return {Promise<void>} The promise for the select action.
   */
  const handleSelectSurfaceChange = async (event, name, isMulti, options) => {
    let useEvent = event;
    if (options && options.action === 'create-option') {
      const variables = { name: useEvent.value };
      const result = await surfaceTypeCreate({
        variables,
        refetchQueries: getRefetchSurfaceTypeQueries
      });
      useEvent = result.data.surfaceType;
    }

    handleSelectChange(useEvent, name || (options && options.name));
  };

  const handleFetchWeather = async () => {
    let uri;

    try {
      setIsSaving(true);

      if (property?.latitude && property?.longitude) {
        uri = WEATHER_API_LAT_LNG_PATH.format({
          latitude: property.latitude,
          longitude: property.longitude
        });
      } else {
        uri = WEATHER_API_PATH.format({
          addressLineOne: property?.addressLineOne,
          city: property?.city?.name,
          zipCode: property?.zipCode,
          state: property?.state?.abbreviation
        });
      }
      const result = await fetchData(uri, 'GET');
      // const result = {
      //    'request': {
      //       'type': 'LatLon',
      //       'query': 'Lat 38.66 and Lon -77.37',
      //       'language': 'en',
      //       'unit': 'm'
      //    },
      //    'location': {
      //       'name': 'Mapledale',
      //       'country': 'United States of America',
      //       'region': 'Virginia',
      //       'lat': '38.653',
      //       'lon': '-77.367',
      //       'timezone_id': 'America\/New_York',
      //       'localtime': '2021-05-10 11:52',
      //       'localtime_epoch': 1620647520,
      //       'utc_offset': '-4.0'
      //    },
      //    'current': {
      //       'observation_time': '03:52 PM',
      //       'temperature': 12,
      //       'weather_code': 122,
      //       'weather_icons': ['https:\/\/assets.weatherstack.com\/images\/wsymbols01_png_64\/wsymbol_0004_black_low_cloud.png'],
      //       'weather_descriptions': ['Overcast'],
      //       'wind_speed': 15,
      //       'wind_degree': 330,
      //       'wind_dir': 'NNW',
      //       'pressure': 1015,
      //       'precip': 0,
      //       'humidity': 64,
      //       'cloudcover': 100,
      //       'feelslike': 9,
      //       'uv_index': 3,
      //       'visibility': 16,
      //       'is_day': 'yes'
      //    }
      // };
      const newEditValues = {
        ...editValues,
        weather: result?.current?.weather_descriptions?.[0]
      };
      setEditValues(newEditValues);
      setWeatherData(result);

      await submitChange(newEditValues);

      setIsFailedWeather(false);
      setIsSaving(false);
    } catch (error) {
      console.log('Error fetching weather', error);
      setIsFailedWeather(true);
      setIsSaving(false);
    }
  };

  if (loading) {
    return statusComponent;
  }
  const surfaceTypeOptions = get(surfaceTypeData, 'surfaceTypes') || [];

  return (
    <Grid
      name={'root'}
      className={classes.root}
      container
      direction={'column'}
      wrap={'nowrap'}
    >
      {savingError && (
        <DisplayError
          error={savingError}
          errorId='answer.savePhoto.error'
          values={savingError}
        />
      )}
      {reportConfirmComponent}
      {statusComponent ||
        reportDeleteStatusComponent ||
        reportUpdateStatus ||
        deleteSurfaceTypeStatusComponent ||
        surfaceTypeStatus ||
        createSurfaceTypeStatusComponent}
      {isCameraOpen && (
        <CameraDialog
          onClose={handleCameraClose}
          onSubmit={handleCameraSubmit}
          allowAnnotations={false}
        />
      )}
      <Prompt
        when={isSaving}
        messageKey={'answer.savePhoto.leave'}
        defaultMessage={'Discard photo?'}
      />
      {isSaving && (
        <Backdrop className={classes.backdropStyle} open={true}>
          <CircularProgress color='inherit' />
        </Backdrop>
      )}
      {overallStatus !== DELETED_STATUS && (
        <Grid
          name={'top row'}
          container
          item
          direction={'column'}
          resizable={false}
          className={classes.headerStyle}
        >
          <Grid item container alignItems={'center'}>
            {overallStatus === COMPLETE_STATUS || isComplete ? (
              <CheckCircle
                fontSize='large'
                style={{ color: isComplete ? '#1796a4' : 'green' }}
              />
            ) : overallStatus === PARTIAL_STATUS ? (
              <RemoveCircle fontSize='large' style={{ color: '#ffc107' }} />
            ) : (
              <CircleIcon fontSize='large' />
            )}
            <Button
              component={Link}
              to={`/inspect/${property.id}`}
              color='primary'
            >
              <Typography variant={'h6'} color={'primary'} display={'inline'}>
                {property.name}
              </Typography>
            </Button>
            <Grid name={'Delete inspection'} item>
              <Grid
                container
                spacing={1}
                alignItems={'center'}
                direction={'row'}
              >
                <Grid item>
                  <Button
                    onClick={doComplete}
                    color={'secondary'}
                    className={classes.leftButtonStyle}
                    disabled={!isEditable}
                  >
                    <Typography
                      color='inherit'
                      id={
                        !isComplete
                          ? 'inspect.complete.button'
                          : 'inspect.inProgress.button'
                      }
                      values={{ questionType }}
                    />
                  </Button>
                </Grid>
                <Grid item>
                  <Divider
                    orientation={'vertical'}
                    className={classes.dividerStyle}
                  />
                </Grid>
                <Grid item>
                  <Button
                    onClick={doShowEdit}
                    color={'secondary'}
                    className={classes.leftButtonStyle}
                    disabled={!isEditable || isComplete}
                  >
                    <Typography
                      color='inherit'
                      id={'inspect.edit.button'}
                      values={{ questionType }}
                    />
                  </Button>
                </Grid>
                <Grid item>
                  <Divider
                    orientation={'vertical'}
                    className={classes.dividerStyle}
                  />
                </Grid>
                <Grid item>
                  <Button
                    onClick={deleteInspection}
                    color={'secondary'}
                    disabled={!isEditable || isComplete}
                    className={classes.rightButtonStyle}
                  >
                    <Typography
                      color='inherit'
                      id={'inspect.delete.button'}
                      values={{ questionType }}
                    />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            name={'property info'}
            container
            item
            direction={'row'}
            justify={'space-between'}
            resizable={false}
            className={`${classes.headerStyle} ${
              isLargeHeader ? undefined : classes.shrinkStyle
            }`}
          >
            <ModalDialog
              titleKey={'inspect.addRemoveQuestions.title'}
              open={showEdit}
              cancelKey={'close.button'}
              onClose={closeEdit}
              maxWidth={'sm'}
              fullWidth
            >
              <Suspense fallback={<Loading isLoading />}>
                <InspectionBuilder
                  property={property}
                  reportId={reportId}
                  questionTypeId={questionTypeId}
                />
              </Suspense>
            </ModalDialog>
            {showConfirm && (
              <ModalDialog
                titleKey={'inspect.finishIncomplete.title'}
                messageKey={'inspect.finishIncomplete.message'}
                onClose={cancelConfirm}
                onSubmit={doComplete}
                submitKey={'inspect.finishIncomplete.button'}
                submitColorStyle={classes.deleteColorStyle}
              />
            )}

            <Grid
              name={'property address'}
              className={
                isLargeHeader
                  ? classes.rightDivider
                  : classes.shrinkRightDivider
              }
              item
              container
              direction={'row'}
              justify={'space-between'}
              xs={12}
              sm={12}
              md={5}
            >
              <Grid container direction={'row'} alignItems={'flex-start'} wrap={'nowrap'} fullWidth>
                <Grid container item fullWidth alignItems={'center'} spacing={3}>
                  <Grid item resizable={false}>
                    <Typography variant={'body2'} color={'inherit'}>
                      {property.addressLineOne}
                    </Typography>
                    <Typography variant={'body2'} color={'inherit'}>
                      {property.addressLineTwo}
                    </Typography>
                    <Typography
                      variant={'body2'}
                      color={'inherit'}
                      id={'propertyInfo.cityStateZip.label'}
                      values={{
                        city: property.city.name,
                        state: property.state.abbreviation,
                        zipCode: property.zipCode
                      }}
                    />
                  </Grid>
                  {questionTypeId === AUDIT_TYPE && (
                    <Grid
                      container
                      direction={'row'}
                      spacing={1}
                      resizable
                      style={{ marginLeft: 8, marginBottom: 8, minWidth: 400 }}
                    >
                      <Grid container item xs={6} wrap={'nowrap'}>
                        <TextField
                          key={'Weather ' + data?.report.weather}
                          name='weather'
                          label={<Typography id={'answer.weather.label'} />}
                          className={classes.weatherStyle}
                          required
                          defaultValue={data?.report.weather}
                          value={editValues.weather}
                          onChange={handleEditChange}
                          // onBlur={() => submitChangeDelayed.flush()}
                          margin='none'
                          disabled={
                            !(isFailedWeather || isAdmin) ||
                            !isEditable ||
                            isComplete
                          }
                          fullWidth
                          variant={'standard'}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                <IconButton
                                  onMouseDown={handleFetchWeather}
                                  disabled={!isEditable || isComplete}
                                >
                                  <CloudDownload />
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                          helperText={
                            weatherData
                              ? `${weatherData?.location?.name}, ${weatherData?.location?.region}`
                              : undefined
                          }
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <ReactSelect
                          name={'surfaceTypeId'}
                          className={classes.weatherStyle}
                          label={
                            <FHGTypography
                              display={'inline'}
                              id={'answer.surface.label'}
                            />
                          }
                          options={surfaceTypeOptions}
                          defaultValue={ReactSelect.createObjectValue(
                            data?.report?.surfaceTypeId,
                            surfaceTypeOptions
                          )}
                          value={ReactSelect.createObjectValue(
                            editValues.surfaceTypeId,
                            surfaceTypeOptions
                          )}
                          hideSelectedOptions
                          isLoading={surfaceTypeLoading}
                          onChange={handleSelectSurfaceChange}
                          onBlur={() => submitChangeDelayed.flush()}
                          isMulti={false}
                          isCreatable
                          isConfirmDeletable
                          onCanDoAction={handleCanSurfaceTypeAction}
                          onDelete={handleSurfaceTypeDelete}
                          openMenuOnClick={false}
                          fullWidth
                          disabled={!isEditable || isComplete}
                          // size={'small'}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid
                name={'property info'}
                item
                container
                className={classes.propertyInfoStyle}
                xs={6}
                sm={6}
                md={3}
            >
              <Grid item>
                <Grid container direction="column">
                  <Grid item>
                    <Typography
                        variant={'body2'}
                        color={'primary'}
                        className={classes.relatedRightSpace}
                        display={'inline'}
                        id={'inspect.propertyType.label'}
                    />
                    <Typography
                        variant={'body2'}
                        color={'inherit'}
                        display={'inline'}
                    >
                      {property.type}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                        variant={'body2'}
                        color={'primary'}
                        className={classes.relatedRightSpace}
                        display={'inline'}
                        id={'inspect.companyName.label'}
                    />
                    <Typography
                        variant={'body2'}
                        color={'inherit'}
                        display={'inline'}
                    >
                      {property.companyName || 'N/A'}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                        variant={'body2'}
                        color={'inherit'}
                        className={classes.relatedTopSpace}
                    >
                      {property.contactName}
                    </Typography>

                    <Typography variant={'body2'} color={'inherit'}>
                      <MuiLink
                          href={`tel:${property.phone}`}
                          className={`${classes.linkStyle} text-small`}
                      >
                        {property.phone}
                      </MuiLink>
                    </Typography>
                    <Typography variant={'body2'} color={'inherit'}>
                      <MuiLink
                          href={`mailto:${property.email}`}
                          underline="always"
                          className={` ${classes.linkStyle}`}
                      >
                        {property.email}
                      </MuiLink>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              name={'property photo'}
              item
              container
              justify={'center'}
              className={classes.propertyPhotoStyle}
              wrap={'nowrap'}
              xs={6}
              sm={6}
              md={4}
            >
              <Grid
                name='inspect image container'
                item
                container
                alignItems={'center'}
                fullWidth={false}
                wrap={'nowrap'}
                className={classes.relatedRightSpace}
                style={{ height: '100%' }}
                spacing={2}
              >
                <Grid item>
                  <IconButton
                    key={`map-maker ${property.id}`}
                    aria-label='Map Marker'
                    onClick={handleCameraOpen}
                    disabled={!isEditable || isComplete}
                  >
                    <PhotoCameraIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <img
                    alt='Property'
                    ref={imageRef}
                    src={(report && report.imageS3) || PLACEHOLDER_IMAGE}
                    className={
                      isLargeHeader
                        ? classes.photoStyle
                        : classes.shrinkPhotoStyle
                    }
                    onError={e => e.target.src = PLACEHOLDER_IMAGE}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid
        name={'middle row (content'}
        container
        item
        direction={'column'}
        wrap={'nowrap'}
      >
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          classes={{ indicator: classes.indicatorStyle }}
          variant='scrollable'
        >
          {questionTypeId !== INCLUSIVE_TYPE && (
            <Tab
              label={
                <Typography variant='subtitle1' className={classes.tabStyle}>
                  {questionType}
                </Typography>
              }
              value={INSPECT_TAB}
            />
          )}
          {questionTypeId === INCLUSIVE_TYPE && (
            <Tab
              label={
                <Typography
                  variant='subtitle1'
                  className={classes.tabStyle}
                  id={'inspect.inclusive.title'}
                />
              }
              value={INCLUSIVE_INSPECTION_TAB}
            />
          )}
          {questionTypeId === INCLUSIVE_TYPE && (
              <Tab
                  label={
                    <Typography
                        variant='subtitle1'
                        className={classes.tabStyle}
                        id={'inspect.comprehensiveInclusiveAudit.title'}
                    />
                  }
                  value={INCLUSIVE_AUDIT_TAB}
              />
          )}
          {questionTypeId === INCLUSIVE_TYPE && (
            <Tab
              label={
                <Typography
                  variant='subtitle1'
                  className={classes.tabStyle}
                  id={'inspect.inclusiveImage.title'}
                />
              }
              value={INCLUSIVE_INSPECTION_IMAGE_TAB}
            />
          )}
          {questionTypeId === INSPECTION_TYPE && (
            <Tab
              label={
                <Typography
                  variant='subtitle1'
                  className={classes.tabStyle}
                  id={'inspect.checklist.title'}
                />
              }
              value={CHECKLIST_TAB}
            />
          )}
          {questionTypeId === AUDIT_TYPE && (
            <Tab
              label={
                <Typography
                  variant='subtitle1'
                  className={classes.tabStyle}
                  id={'inspect.perimeterConcerns.title'}
                />
              }
              value={PERIMETER_CONCERNS_TAB}
            />
          )}
          {questionTypeId !== INCLUSIVE_TYPE && (
            <Tab
               label={
               <Typography
                  variant='subtitle1'
                  className={classes.tabStyle}
                  id={'inspect.conclusion.title'}
               />
               }
               value={CONCLUSION_TAB}
            />
          )}
          {questionTypeId === AUDIT_TYPE && (
            <Tab
              label={
                <Typography
                  variant='subtitle1'
                  className={classes.tabStyle}
                  id={'inspect.summary.title'}
                />
              }
              value={SUMMARY_TAB}
            />
          )}
          {questionTypeId === INCLUSIVE_TYPE && (
            <Tab
              label={
                <Typography
                  variant='subtitle1'
                  className={classes.tabStyle}
                  id={'inspect.summary.title'}
                />
              }
              value={SUMMARY_TAB}
            />
          )}
          {questionTypeId !== INCLUSIVE_TYPE && (
          <Tab
            label={
              <Typography
                variant='subtitle1'
                className={classes.tabStyle}
                id={'inspect.notes.title'}
              />
            }
            value={NOTES_TAB}
          />
        )}
        </Tabs>
        {selectedTab === INSPECT_TAB && (
          <Grid item overflow={'auto'} style={{ height: '100%' }} resizable>
            <InspectionPanel
              property={property}
              report={report}
              areas={statusAreas}
              overallStatus={overallStatus}
              isInProgress={isInProgress && isEditable}
              isEditable={isEditable && !isComplete}
            />
          </Grid>
        )}
        {selectedTab === INCLUSIVE_INSPECTION_TAB && (
          <Grid item overflow={'auto'} style={{ height: '100%' }} resizable>
          <InspectionPanel
            property={property}
            report={report}
            areas={statusAreas}
            overallStatus={overallStatus}
            isInProgress={isInProgress && isEditable}
            isEditable={isEditable && !isComplete}
          />
        </Grid>
        )}
        {selectedTab === INCLUSIVE_AUDIT_TAB && (
            <Grid item overflow={'auto'} style={{ height: '100%' }} resizable>
              <MuiThemeProvider theme={responsiveFontSizes(createTheme(materialTheme))}>
                <ComprehensiveInclusiveAudit
                    report={report}
                    isEditable={isEditable && !isComplete}
                />
              </MuiThemeProvider>
            </Grid>
        )}
        {selectedTab === INCLUSIVE_INSPECTION_IMAGE_TAB && (
          <Grid item overflow={'auto'} style={{ height: '100%' }} resizable>
            <StyledDropZone
              accept={["image/jpeg", "image/png"]}
              image={image?.blob || convertImageToWrapper(report.inclusiveImageData?.imageS3)}
              onDrop={handleDrop}
              labelKey="inspect.image.label"
            />
          </Grid>
        )}
        {selectedTab === CHECKLIST_TAB && (
          <Grid item overflow={'auto'} style={{ height: '100%' }} resizable>
            <InspectionChecklist
              report={report}
              isEditable={isEditable && !isComplete}
            />
          </Grid>
        )}
        {selectedTab === CONCLUSION_TAB && (
          <Grid item overflow={'auto'} style={{ height: '100%' }} resizable>
            <Conclusion
              report={report}
              isEditable={isEditable && !isComplete}
            />
          </Grid>
        )}
        {selectedTab === SUMMARY_TAB && (
          <Grid item overflow={'auto'} style={{ height: '100%' }} resizable>
            <Summary report={report} isEditable={isEditable && !isComplete} />
          </Grid>
        )}
        {selectedTab === PERIMETER_CONCERNS_TAB && (
          <Grid
            item
            overflow={'auto'}
            style={{ height: '100%', position: 'relative' }}
            resizable
          >
            <PerimeterConcernsBuilder
              report={report}
              isEditable={isEditable && !isComplete}
            />
          </Grid>
        )}
        {selectedTab === NOTES_TAB && (
          <Grid
            item
            overflow={'auto'}
            style={{ height: '100%', position: 'relative' }}
            resizable
          >
            <TechnicalNotes
              propertyId={property?.id}
              isEditable={isEditable && !isComplete}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
