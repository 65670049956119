import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {withStyles} from '@material-ui/core/styles';
import {isEqual} from 'lodash';
import filter from 'lodash/filter';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {drawerWidth, PROPERTIES_ALL_PATH} from '../Constants';
import Typography from '../fhg/components/Typography';
import {parse} from 'query-string';

const styles = theme => ({
   titleStyle: {
      margin: theme.spacing(3, 2, 0, 2),
   },
   drawerStyle: {
      width: drawerWidth,
      flexShrink: 0,
   },
   paperStyle: {
      width: drawerWidth,
      color: 'white',
      backgroundColor: theme.palette.type === 'dark' ? theme.palette.primary.dark : theme.palette.primary.main,
   },
   progressStyle: {
      position: 'relative',
      top: '50%',
      left: '45%',
   },
   toolbar: theme.mixins.toolbar,
   newButtonStyle: {
      backgroundColor: theme.palette.type === 'dark' ? theme.palette.primary.dark : theme.palette.primary.main,
      position: 'sticky',
      bottom: -theme.spacing(1),
   },
   buttonStyle: {
      fontWeight: 500,
   },
   focusVisible: {
      backgroundColor: 'transparent !important',
   },
   noScrollStyle: {
      overflow: 'hidden',
   },
   scrollStyle: {
      overflow: 'auto',
   },
   actionButtonStyle: {
      color: 'white',
      margin: theme.spacing(3, 2, 0, 2),
   },
   hide: {
      display: 'none',
   }
});

/**
 * Drawer to navigate to the items.
 */
class ItemDrawer extends Component {
   static propTypes = {
      items: PropTypes.array,
      isLoading: PropTypes.bool,
      prefixKey: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
      ItemEdit: PropTypes.elementType.isRequired,
      ItemEditProps: PropTypes.object,
      useSearch: PropTypes.bool,
      allowCreate: PropTypes.bool,
      onSubmit: PropTypes.func,
      filter: PropTypes.any,
      open: PropTypes.bool,
      variant: PropTypes.string,
   };

   static defaultProps = {
      items: [],
      isLoading: false,
      useSearch: true,
      allowCreate: false,
      variant: 'permanent',
      open: false,
   };

   constructor(props, context) {
      super(props, context);

      const searchText = get(parse(props.location.search), 'searchText');
      const filteredItems = filter(props.items, props.filter);
      this.state = {
         showNew: false,
         filteredItems: this.search(searchText, filteredItems),
      };
   }

   componentDidMount() {
      const isCreateProperty = get(parse(this.props.location.search), 'isCreateProperty')
      if (Boolean(isCreateProperty) === true) {
         this.setState({showNew: true});
      }
   }

   componentDidUpdate(prevProps, prevState, snapshot) {
      const { uri, items, history, location } = this.props;
      const searchOld = get(parse(prevProps.location.search), 'searchText');
      const searchNew = get(parse(location.search), 'searchText');

      if ((searchOld !== searchNew) || !isEqual(prevProps.filter, this.props.filter) || !isEqual(prevProps.items, items)) {
         const filteredItems = filter(items, this.props.filter);

         this.setState({filteredItems: this.search(searchNew, filteredItems)}, () => {
            if (this.state.filteredItems.length === 1) {
               history.push(`${uri}/${this.state.filteredItems[0].id}${location.search}`);
            }
         });
      }

      const isCreatePropertyOld = get(parse(prevProps.location.search), 'isCreateProperty')
      const isCreatePropertyNew = get(parse(this.props.location.search), 'isCreateProperty')
      if (Boolean(isCreatePropertyOld) !== Boolean(isCreatePropertyNew)) {
         this.setState({showNew: Boolean(isCreatePropertyNew)});
      }
   }

   search = (searchText = '', items = []) => {
      const { useSearch, onGetName } = this.props;
      let filteredItems = [];

      if (useSearch && searchText.length > 0 && items.length > 0) {
         let search = searchText.toLocaleLowerCase().trim();

         if (onGetName) {
            filteredItems = filter(items, o => onGetName(o).toLocaleLowerCase().indexOf(search) >= 0);
         } else {
            filteredItems = filter(items, o => o.name && o.name.toLocaleLowerCase().indexOf(search) >= 0);
         }
      } else {
         filteredItems = items || [];
      }
      return filteredItems;
   };

   openNew = () => {
      const {history, uri, allowCreate, ItemEdit} = this.props;

      if (ItemEdit) {
         this.setState({showNew: true});
      } else if (allowCreate){
         history.push(uri, {isCreate: true});
      }
   };

   closeNew = () => {
      const isCreateProperty = get(parse(this.props.location.search), 'isCreateProperty')
      if (Boolean(isCreateProperty) === true) {
         this.props.history.push(PROPERTIES_ALL_PATH);
      }
      this.setState({showNew: false});
   };

   render() {
      const {classes, location, prefixKey, uri, onGetName, ItemEdit, ItemEditProps, open, variant, onSubmit, children} = this.props;
      const {showNew, filteredItems = []} = this.state;

      return (
         <Drawer className={`no-print ${classes.drawerStyle} ${!open && classes.hide}`} variant={variant}
                 classes={{paper: classes.paperStyle}} anchor='left' open={open}>
            <div className={classes.toolbar}/>
            {(ItemEdit && showNew) && <ItemEdit open={showNew} onClose={this.closeNew} isCreate {...ItemEditProps}/>}
            <Grid container alignItems={'center'} justify={'space-between'}>
               <Grid item>
                  <Typography variant={'h6'} className={classes.titleStyle} color={'inherit'}
                              id={`${prefixKey}.title`}/>
               </Grid>
               {onSubmit && (
                  <Grid item>
                     <Button className={classes.actionButtonStyle} onClick={onSubmit}>
                        <Typography variant={'button'} color={'inherit'} id={'export.label'}/>
                     </Button>
                  </Grid>
               )}
            </Grid>
            {children}
            <Grid item className={classes.newButtonStyle}>
               <Divider/>
               <ListItem button onClick={this.openNew} selected={false} classes={{focusVisible: classes.focusVisible}}>
                  <Typography variant={'inherit'} className={classes.buttonStyle}
                              id={`${prefixKey}.newButton.label`}/>
               </ListItem>
               <Divider/>
            </Grid>
            <Grid container direction={'column'} className={classes.noScrollStyle}>
               <List className={classes.scrollStyle}>
                  <Fragment>
                     {filteredItems.map(item => (
                        <ListItem
                           key={`itemDrawer${item.id}`}
                           button
                           component={Link}
                           to={`${uri}/${item.id}`}
                           selected={location.pathname === `${uri}/${item.id}`}
                        >
                           <ListItemText primary={onGetName ? onGetName(item) : item.name}/>
                        </ListItem>
                     ))}
                  </Fragment>
               </List>
            </Grid>
         </Drawer>
      );
   }
}
export default withRouter(withStyles(styles)(ItemDrawer));
