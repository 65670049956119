import CircularProgress from '@material-ui/core/CircularProgress';
import domtoimage from 'dom-to-image';
import React from 'react';
import DisplayError from '../DisplayError';
import {dataURLToBlob} from '../Video';

export const loadingAndError = (loading, error) => {
   if (loading) {
      return <CircularProgress className={'progress'}/>;
   } else if (error) {
      console.log(error);
      return <DisplayError error={error} errorId='fetch.error' values={error}/>
   }
};

export const saveImage = (elementId, imageBlobName) => {
   return new Promise(async (resolve, reject) => {
      const imageEl = document.getElementById(elementId);

      if (imageEl) {
         domtoimage.toBlob(imageEl)
            .then(function (blob) {
               blob.name = imageBlobName;
               const src = URL.createObjectURL(blob);
               resolve({src, blob});
            })
      } else {
         console.log('No image element');
         reject('No image element.');
      }
   });
};
