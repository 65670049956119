import gql from 'graphql-tag';

export const PROPERTY_FRAGMENT = gql`
   fragment propertyInfo on Property {
      id
      name
      isDeleted
      zipCode
      addressLineOne
      addressLineTwo
      phone
      email
      companyName
      propertyTypeId
      type
      companyName
      contactName
      information
      schedule:scheduleOfInspections
      city {
         id
         isDeleted
         name
      }
      state {
         id
         isDeleted
         value:id
         name
         abbreviation
         label:name
      }
      imageS3
      questionIdList
      questions:questionList {
         id
         isDeleted
         equipmentId:masterEquipmentId
         questionTypeId
      }
      userIdList
      regionId
      region {
         id
         isDeleted
         value:id
         name
         label:name
      }
       longitude
       latitude
   }
`;

export const QUESTION_ALIAS_FRAGMENT = gql`
   fragment questionAliasInfo on QuestionAlias {
      id
      isDeleted
      equipmentId
      questionId
      markedForAdd
      markedForRemove
      question {
         id
         isDeleted
         text
      }
   }
`;

export const PERIMETER_CONCERN_FRAGMENT = gql`
   fragment perimeterConcernInfo on PerimeterConcern {
      id
      isDeleted
      comments
      distance
      perimeterConcernTypeId
      priorityId
   }
`;

